import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Titlebar = _resolveComponent("Titlebar")!
  const _component_DxButton = _resolveComponent("DxButton")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_SmartTableV2 = _resolveComponent("SmartTableV2")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Titlebar, {
      title: "Workflow Instanzen",
      description: "Achtung, wenn eine Instanz einer Arbeitsstation gestoppt wird, muss der Workflow beim Arbeitsplatz neu entfernt und neu zugewiesen werden, damit der Workflow wieder läuft."
    }),
    _createVNode(_component_SmartTableV2, {
      tableId: "workflowInstances",
      ref: "workflowInstances",
      dataStore: _ctx.dataStore,
      key: "key",
      hideAddButton: true,
      hideFunctionColumn: true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DxColumn, { type: "buttons" }, {
          default: _withCtx(() => [
            _createVNode(_component_DxButton, { name: "delete" })
          ]),
          _: 1
        }),
        _createVNode(_component_DxColumn, {
          "data-field": "key",
          caption: "Key",
          visible: false,
          "allow-editing": false,
          "data-type": "string"
        }),
        _createVNode(_component_DxColumn, {
          "data-field": "workflowDefinition.title",
          caption: "Workflow-Name",
          visible: true,
          "allow-editing": false,
          "data-type": "string"
        }),
        _createVNode(_component_DxColumn, {
          "data-field": "workflowDefinition.description",
          caption: "Beschreibung",
          visible: true,
          "allow-editing": false,
          "data-type": "string"
        }),
        _createVNode(_component_DxColumn, {
          "data-field": "sourceName",
          caption: "Quelle",
          visible: true,
          "allow-editing": false,
          "data-type": "string"
        }),
        _createVNode(_component_DxColumn, {
          "data-field": "sourcePlugin",
          caption: "Plugin",
          visible: true,
          "allow-editing": false,
          "data-type": "string"
        })
      ]),
      _: 1
    }, 8, ["dataStore"])
  ]))
}