
import { Options, Vue } from 'vue-class-component';
import { DipODataStore, AlertService, GenericErrorHandler, SmartTableV2, Titlebar } from 'rey-web-toolkit';
import axios from 'axios';
import { DxButton, DxColumn, DxLoadPanel, DxLookup, DxMasterDetail, DxSelection } from 'devextreme-vue/data-grid';
import WorkflowTypeEnum from '@/models/WorkflowTypeEnum';
import DxTooltip from 'devextreme-vue/tooltip';
import { Container } from 'typescript-ioc';
import FeatureFlagService from '@/services/FeatureFlagService';

@Options({
  components: {
    DxColumn,
    DxSelection,
    DxLoadPanel,
    DxLookup,
    DxMasterDetail,
    DxButton,
    SmartTableV2,
    Titlebar,
    DxTooltip,
    axios,
  },
})
export default class WorkflowEditorOverview extends Vue {
  private isLoading = false;
  private alertService: AlertService = new AlertService();
  private featureFlagService = Container.get<FeatureFlagService>(FeatureFlagService);
  public dataStore: any | undefined = undefined;
  public workflowTypes = [
    { key: WorkflowTypeEnum.Workstation, name: 'Arbeitsstation' },
    { key: WorkflowTypeEnum.Background, name: 'Hintergrund' },
    { key: WorkflowTypeEnum.BackgroundForLogs, name: 'Hintergrund für LogEvents' },
  ];

  public async beforeCreate() {
    document.title = 'System - Workflow Instanzen';
    this.dataStore = {
      store: DipODataStore.forODataV4Endpoint(
        {
          url: process.env.BASE_URL + `odata/WorkflowInstance`,
          key: 'key',
          keyType: 'Guid',
        },
        [],
        (error: any) => GenericErrorHandler.handleODataV4Error(error),
      ),
      sort: 'title',
      expand: ['workflowDefinition'],
    };
  }
}
